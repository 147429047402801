import React from 'react'
import { createRoot } from 'react-dom/client'
import { Logos } from "./../home/Logos";
import { FeatureCards } from "./../home/FeatureCards";
import { HoverScreenCard } from "./../home/HoverScreenCard";
import { WhoIsItFor } from "./../home/WhoIsItFor";
import { FloatingBottomNav } from "./../shared/FloatingBottomNav";

// used for rendering the composition in the live app
//registerRoot(RemotionRoot);

const attachReact = (el, cb) => {
  const node = document.getElementById(el)
  if (node) { cb(node) }
}

document.addEventListener('turbo:load', () => {
  attachReact('react-home-logos', function (node) {
    const root = createRoot(node)
    root.render(<Logos {...JSON.parse(node.getAttribute('data')) } />)
  })
  attachReact('react-home-feature-cards', function (node) {
    const root = createRoot(node)
    root.render(<FeatureCards {...JSON.parse(node.getAttribute('data')) } />)
  })
  attachReact('react-home-hover-screen-card', function (node) {
    const root = createRoot(node)
    root.render(<HoverScreenCard {...JSON.parse(node.getAttribute('data')) } />)
  })
  attachReact('react-home-whoisitfor', function (node) {
    const root = createRoot(node)
    root.render(<WhoIsItFor {...JSON.parse(node.getAttribute('data')) } />)
  })
  attachReact('react-floating-bottom-nav', function (node) {
    const root = createRoot(node)
    root.render(<FloatingBottomNav {...JSON.parse(node.getAttribute('data')) } />)
  })
  //attachReact('react-video-form', function (node) {
    //const root = createRoot(node)
    //root.render(<VideoEdit {...JSON.parse(node.getAttribute('data')) } />)
  //})
  //attachReact('react-video-new', function (node) {
    //const root = createRoot(node)
    //console.log('loading react-video-new from turbo:load')
    //root.render(<VideoNew {...JSON.parse(node.getAttribute('data')) } />)
  //})
})
