import React from "react";
import { motion } from "framer-motion";
import { FiArrowRight, FiGitPullRequest, FiArrowUpRight } from "react-icons/fi";
import {
  SiFacebook, SiYoutube, SiTiktok, SiAmazon, SiInstagram, SiX, SiLinkedin, SiDiscord, SiWhatsapp, SiTwitch,
  SiGoogle, SiReddit, SiSlack, SiBluesky, SiYelp, SiSkillshare, SiFiverr, SiTrustpilot
} from "react-icons/si";

export const HoverScreenCard = () => {
  return (
    <motion.div whileHover="hovered">
      <ScreenMock />
    </motion.div>
  );
};

const ScreenMock = () => {
  return (
    // Light Gradient Background
    <motion.div
      variants={{
        hovered: {
          rotateY: "15deg",
          rotateX: "2.5deg",
          x: -10,
        },
      }}
      style={{
        transformStyle: "preserve-3d",
      }}
      transition={{
        duration: 0.35,
      }}
      className="w-full h-80 rounded-2xl p-4 bg-gradient-to-br from-violet-300 to-sspurple"
    >
      {/* Browser Screen */}
      <div
        style={{ transform: "translateZ(80px)", transformStyle: "preserve-3d" }}
        className="w-full h-full bg-slate-900 rounded-xl shadow-lg p-2 relative"
      >
        {/* Browser Buttons */}
        <div className="flex gap-1 mt-1 relative">
          <span className="inline-block w-2 h-2 rounded-full bg-red-500"></span>
          <span className="inline-block w-2 h-2 rounded-full bg-yellow-500"></span>
          <span className="inline-block w-2 h-2 rounded-full bg-green-500"></span>
        </div>
        {/* Browser Mockup */}
        <div
          style={{
            transformStyle: "preserve-3d",
          }}
          className="p-2 rounded-md absolute top-8 bottom-2 left-2 right-2 bg-slate-800 grid gap-4 grid-cols-6 grid-rows-6"
        >
          <div
            style={{ transform: "translateZ(40px)" }}
            className="rounded-lg w-full col-span-6 row-span-1 bg-slate-700"
          />
          <div
            style={{ transform: "translateZ(20px)" }}
            className="rounded-lg w-full col-span-1 row-span-5 bg-slate-700"
          />
          <div
            style={{ transform: "translateZ(80px)" }}
            className="rounded-lg w-full col-span-3 row-span-5 bg-gradient-to-br from-ssgreen to-ssgreen-alt flex items-center justify-center flex flex-wrap gap-2"
          >
            <SiFacebook className="text-3xl" />
            <SiInstagram className="text-3xl" />
            <SiTiktok className="text-3xl" />
            <SiYoutube className="text-3xl" />
            <SiAmazon className="text-3xl" />
            <SiX className="text-3xl" />
            <SiTwitch className="text-3xl" />
            <SiWhatsapp className="text-3xl" />
            <SiGoogle className="text-3xl" />
            <SiSlack className="text-3xl" />
            <SiReddit className="text-3xl" />
            <SiBluesky className="text-3xl" />
            <SiYelp className="text-3xl" />
            <SiTrustpilot className="text-3xl" />
            <SiFiverr className="text-3xl" />
          </div>
          <div
            style={{ transform: "translateZ(120px)" }}
            className="rounded-lg w-full col-span-2 row-span-5 bg-gradient-to-br from-ssorange to-ssyellow flex items-center justify-center"
          >
            <FiArrowUpRight className="text-7xl" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
