import React from "react";
import { MotionConfig, motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";

export const FeatureCards = () => {
  return (
    <section className="px-8 py-10">
      <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 sm:grid-cols-2">
        <Card
          title="Collect"
          link="collect"
          subtitle="Customizable forms. Collect audio, video, and text. Super powerful dashboard."
          className="bg-ssorange"
        />
        <Card
          title="Manage"
          link="manage"
          subtitle="Organize your data. Search, filter, download, export, and sort. "
          className="bg-sspurple"
        />
        <Card
          title="Share"
          link="share"
          subtitle="Walls of Love, embeds, and widgets. Instantly create videos and images."
          className="bg-ssblue"
        />
        <Card
          title="Who's it for?"
          link="whoisitfor"
          subtitle="Businesses, actors, writers, podcasters, youtubers, coders, community managers... Everyone."
          className="bg-ssgreen"
        />
      </div>
    </section>
  );
};

const Card = ({ title, link, subtitle, className }) => {
  const show = () => {
    const element = document.getElementById(link);
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <MotionConfig
      transition={{
        type: "spring",
        bounce: 0.5,
      }}
    >
      <motion.div
        whileHover="hovered"
        className={`group w-full rounded-md border-2 border-black ${className}`}
      >
        <motion.div
          initial={{
            x: 0,
            y: 0,
          }}
          variants={{
            hovered: {
              x: -8,
              y: -8,
            },
          }}
          className={`-m-0.5 rounded-md border-2 border-black ${className}`}
        >
          <motion.div
            initial={{
              x: 0,
              y: 0,
            }}
            variants={{
              hovered: {
                x: -8,
                y: -8,
              },
            }}
            className={`relative text-white -m-0.5 flex h-72 flex-col rounded-md justify-between overflow-hidden border-2 border-black p-8 ${className}`}
          >
            <p className="flex items-center text-2xl font-extrabold uppercase">
              <FiArrowRight className="-ml-8 mr-2 opacity-0 transition-all duration-300 ease-in-out group-hover:ml-0 group-hover:opacity-100" />
              {title}
            </p>
            <div>
              <p className="transition-[margin] duration-300 ease-in-out group-hover:mb-10">
                {subtitle}
              </p>
              <div onClick={show} className="absolute bottom-2 left-2 right-2 translate-y-full cursor-pointer border-2 border-black bg-white px-4 py-2 text-black opacity-0 transition-all duration-300 ease-in-out group-hover:translate-y-0 group-hover:opacity-100">
                See Features
              </div>
            </div>

            <motion.svg
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{
                duration: 25,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear",
              }}
              style={{
                top: "0",
                right: "0",
                x: "50%",
                y: "-50%",
                scale: 0.75,
              }}
              width="200"
              height="200"
              className="pointer-events-none absolute z-10 rounded-full"
            >
              <path
                id="circlePath"
                d="M100,100 m-100,0 a100,100 0 1,0 200,0 a100,100 0 1,0 -200,0"
                fill="none"
              />
              <text>
                <textPath
                  href="#circlePath"
                  fill="black"
                  className="fill-black text-2xl font-black uppercase opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
                >
                  LEARN MORE • LEARN MORE • LEARN MORE • LEARN MORE •
                </textPath>
              </text>
            </motion.svg>
          </motion.div>
        </motion.div>
      </motion.div>
    </MotionConfig>
  );
};
