import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import {
  FiShoppingCart,
  FiSearch,
  FiLogIn,
  FiMenu,
  FiHome,
  FiX,
} from "react-icons/fi";

const FloatingBottomNav = () => {
  const [open, setOpen] = useState(false);

  return (
      <motion.nav
        animate={open ? "open" : "closed"}
        initial="closed"
        className="bg-ssbeige w-full text-ssblue flex items-center justify-between fixed top-0 left-[50%] -translate-x-[50%]"
      >
        <MenuButton setOpen={setOpen} open={open} />
        <div className="flex justify-between items-center gap-5 px-6">
          <Link url="/home" text="Home" />
          <Link url='/pricing' text="Pricing" />
          <Link url="/signup" text="Sign Up" />
          <img className="h-8" src="https://images.shosay.com/shosay-logo-bubblegum.png" alt="Shosay Logo" />
        </div>
        <Menu />
      </motion.nav>
  );
};

const Link = ({ url, text, Icon }) => {
  return (
    <a href={url} className="text-sm flex gap-2 items-center" >
      <span className="font-semibold">{text}</span>
    </a>
  );
};

const MenuButton = ({ open, setOpen }) => {
  return (
    <div
      onClick={() => setOpen((pv) => !pv)}
      className="text-xl font-bold h-full bg-ssorange text-white"
    >
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="p-4"
      >
        <AnimatePresence mode="wait">
          {open ? (
            <motion.span
              key="icon-1"
              className="block"
              variants={iconVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.125, ease: "linear" }}
            >
              <FiX />
            </motion.span>
          ) : (
            <motion.span
              key="icon-2"
              className="block"
              variants={iconVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.125, ease: "linear" }}
            >
              <FiMenu />
            </motion.span>
          )}
        </AnimatePresence>
      </motion.button>
    </div>
  );
};

const Menu = () => {
  return (
    <motion.div
      variants={menuVariants}
      style={{ transformOrigin: "bottom", x: "-50%" }}
      className="p-2 bg-ssbeige-alt shadow-lg absolute top-[125%] left-[50%] flex w-[calc(100vw_-_48px)] w-full"
    >
      <div className="flex flex-col gap-2 w-1/3">
        <SectionTitle text="Collect" />
        <MenuLink url="#collect" text="Customizeable Forms" />
        <MenuLink url="#collect" text="Import" />
        <MenuLink url="#collect" text="Automatic Collection" />
      </div>
      <div className="flex flex-col gap-2 w-1/3">
        <SectionTitle text="Manage" />
        <MenuLink url="#manage" text="Grouping and Labels" />
        <MenuLink url="#manage" text="Search" />
        <MenuLink url="#manage" text="Filters" />
        <MenuLink url="#manage" text="Downloads and exports" />
      </div>
      <div className="flex flex-col gap-2 w-1/3">
        <SectionTitle text="Share" />
        <MenuLink url="#share" text="Walls of Love" />
        <MenuLink url="#share" text="Videos & Images" />
        <MenuLink url="#share" text="Embeds" />
        <MenuLink url="#share" text="Widgets" />
        <MenuLink url="#share" text="Social Media" />
      </div>
    </motion.div>
  );
};

const SectionTitle = ({ text }) => {
  return (
    <motion.h4
      variants={menuLinkVariants}
      className="text-sm mb-2 font-semibold"
    >
      {text}
    </motion.h4>
  );
};

const MenuLink = ({ text, url }) => {
  return (
    <motion.a
      variants={menuLinkVariants}
      href={url}
      rel="nofollow"
      className="text-sm hover:text-indigo-500 transition-colors flex items-center gap-2"
    >
      {text}
    </motion.a>
  );
};

export { FloatingBottomNav };

const iconVariants = {
  initial: { rotate: 180, opacity: 0 },
  animate: { rotate: 0, opacity: 1 },
  exit: { rotate: -180, opacity: 0 },
};

const menuVariants = {
  open: {
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
    },
  },
  closed: {
    scale: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
    },
  },
};

const menuLinkVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: -15,
    opacity: 0,
  },
};
