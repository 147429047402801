import React, { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { MotionConfig, motion, AnimatePresence } from "framer-motion";

export const WhoIsItFor = () => {
  const [selected, setSelected] = useState(0);

  return (
    <section className="relative mx-auto flex max-w-6xl flex-col items-start gap-4 px-2 md:flex-row md:gap-8 md:px-4">
      <Copy selected={selected} setSelected={setSelected} />
      <Users selected={selected} />
    </section>
  );
};

const Copy = ({ selected, setSelected }) => {
  return (
    <div className="w-full">
      <h2 className="mb-3 text-center text-4xl font-bold leading-tight md:text-start md:text-5xl md:leading-tight">
        Is Shosay for you?
      </h2>
      <p className="mb-6 text-center text-base leading-relaxed md:text-start md:text-lg md:leading-relaxed">
        How can you use Shosay? The possibilities are endless. Here are some of the ways people are using Shosay.
      </p>
      <div className="mb-6 flex flex-wrap justify-center gap-3 md:justify-start">
        {OPTIONS.map((o, i) => {
          return (
            <CheckPill
              key={o.title}
              index={i}
              selected={i === selected}
              setSelected={setSelected}
            >
              {o.title}
            </CheckPill>
          );
        })}
      </div>
    </div>
  );
};

export const Users = ({ selected }) => {
  const { Content } = OPTIONS[selected];

  return (
    <div className="w-full translate-y-2 rounded-lg bg-zinc-900">
      <AnimatePresence mode="wait">
        <Content key={selected} />
      </AnimatePresence>
    </div>
  );
};

const CheckPill = ({ children, selected, setSelected, index }) => (
  <div className="rounded-full bg-slate-900">
    <button
      onClick={() => setSelected(index)}
      className={`flex origin-top-left items-center gap-1 rounded-full border px-1.5 py-0.5 text-sm transition-all ${selected ? "-rotate-3 border-slate-900 bg-ssgreen text-white" : "bg-white border-zinc-900 hover:bg-zinc-200"}`}
    >
      <FiCheckCircle /> {children}
    </button>
  </div>
);

const Testimonial = ({ imgSrc, name, title, company, content }) => (
  <MotionConfig
    transition={{
      duration: 0.2,
      ease: "easeInOut",
    }}
  >
    <motion.div
      initial={{
        y: 0,
      }}
      animate={{
        y: -8,
      }}
      exit={{
        y: 0,
      }}
      className="w-full overflow-hidden rounded-lg border-2 border-zinc-900 bg-white p-8 md:p-12"
    >
      <div className="mb-6 flex items-center gap-6">
        <div className="rounded-lg bg-zinc-900">
          <motion.img
            initial={{
              rotate: "0deg",
              opacity: 0,
            }}
            animate={{
              rotate: "3deg",
              opacity: 1,
            }}
            exit={{
              rotate: "0deg",
              opacity: 0,
            }}
            src={imgSrc}
            alt="avatar"
            className="size-24 rounded-lg border-2 border-zinc-900 bg-indigo-200"
          />
        </div>
        <motion.div
          initial={{
            y: 12,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: -12,
            opacity: 0,
          }}
        >
          <span className="mb-1.5 block text-3xl font-medium">{name}</span>
          <span className="text-zinc-600">
            {title} – <span className="text-indigo-600">{company}</span>
          </span>
        </motion.div>
      </div>
      <motion.p
        initial={{
          y: 12,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        exit={{
          y: -12,
          opacity: 0,
        }}
        className="text-xl leading-relaxed"
      >
        {content}
      </motion.p>
    </motion.div>
  </MotionConfig>
);

export const OPTIONS = [
  {
    title: "Voice Actors",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/homeav3.jpeg"
        name="Morgan Chen"
        title="Voice Actor"
        company="MorganChenVO"
        content="First Casting Call Club, then Closing Credits, now Shosay. Buford is a godsend. I can't believe it's free. For a broke ass voice actor like me, it's a game-changer. I love you. I hope that's not weird."
      />
    ),
  },
  {
    title: "YouTubers",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/hometest7.webp"
        name="The Merla"
        title="YouTuber"
        company="BigMerla"
        content="The ability to easily share curated testimonials with potential sponsors has helped me land some amazing partnerships – and I didn't have to spend a dime!"
      />
    ),
  },
  {
    title: "Saas Companies",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/hometest6.webp"
        name="Sofia Jenkins"
        title="Marketing"
        company="videomancy.com"
        content="Our customer success stories were scattered across emails, LinkedIn, and various feedback forms. Shosay is just the best testimonial platform out there. If they charged for it, I'd still pay."
      />
    ),
  },
  {
    title: "Authors",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/homeav5.jpg"
        name="James Leonard"
        title="Author"
        company="Startup Stories"
        content="Getting reader testimonials is crucial for book promotions. Shosay simplified everything. The platform's user-friendly interface and zero cost make it perfect for independent authors like me."
      />
    ),
  },
  {
    title: "Agencies",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/hometest5.webp"
        name="Patty Wong"
        title="CEO"
        company="Spark Digital"
        content="Managing testimonials for multiple clients and campaigns used to be a logistical headache. Shosay's organizational features have streamlined our success stories. The fact that it's free means we can allocate our budget to other crucial areas."
      />
    ),
  },
  {
    title: "Job Seekers",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/hometest8.webp"
        name="Michael Thompson"
        title="Former Project Manager"
        company="Seattle Tech"
        content="Shosay has revolutionized how I present my professional references. Instead of the traditional reference list, I now have a dynamic collection of testimonials from previous employers and colleagues. The platform's professional presentation has helped me stand out in job applications, and being free makes it accessible to everyone in career transition."
      />
    ),
  },
  {
    title: "Freelancers",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/hometest4.webp"
        name="Emma White"
        title="Musician and Photographer"
        company="TorontoPictureLady"
        content="The ability to organize testimonials by project type and share them instantly with potential clients has been invaluable."
      />
    ),
  },
  {
    title: "Course Creators",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/homeav2.jpg"
        name="Brendan"
        title="Film Maker"
        company="@DivineDigital"
        content="Managing student success stories across multiple courses was becoming overwhelming until I discovered Shosay. Now, I can easily collect and display testimonials for each course separately. The platform's intuitive interface and comprehensive features have helped boost my course enrollment rates significantly. The fact that such a powerful tool is available for free is amazing!"
      />
    ),
  },
  {
    title: "People",
    Content: () => (
      <Testimonial
        imgSrc="https://images.shosay.com/homeav4.webp"
        name="Buford Taylor"
        title="Regular Guy"
        company="My House"
        content="I just like to build stuff. What really makes me happy is when I can make something that helps someone else. Collecting feedback brings me joy. Shosay does that for me."
      />
    ),
  },
];
